<template>
    <div class="section-four w-100 pb-5 pt-lg--10">
        <div class="row justify-content-center">
            <div class="col-10">
                <div class="row justify-content-center my-5 content">
                    <div class="col-12">
                        <div class="row justify-content-center mb-5">
                            <div class="col-12 text-center">
                                <img src="/images/program/toefl_ibt.svg" alt="SVG Image" class="img-fluid" />
                            </div>
                        </div>
                        <div class="row justify-content-center">
                            <div class="page-title style1 col-12 mb-2">
                                <h2 class="text-grey-900 fw-700 font-lg mb-0 pb-3 d-block">Informasi Umum</h2>
                            </div>
                            <div class="col-12 mb-5">
                                <p>TOEFL iBT® (Test of English as a Foreign Language Internet-Based Test) merupakan tes yang
                                    mengukur kemampuan bahasa Inggris untuk penutur non-asli bahasa Inggris yang
                                    dikembangkan oleh ETS (Educational Testing Service). Tes ini menggunakan format berbasis
                                    internet, yang memungkinkan peserta untuk mengikuti ujian melalui komputer dengan akses
                                    internet.</p>
                            </div>
                        </div>
                        <div class="row justify-content-center">
                            <div class="page-title style1 col-12 mb-2">
                                <h2 class="text-grey-900 fw-700 font-lg mb-0 pb-3 d-block">Manfaat TOEFL iBT®</h2>
                            </div>
                            <div class="col-12 mb-5">
                                <p>
                                <ol>
                                    <li class="number">Penerimaan ke program gelar melalui pengujian yang diselenggarakan oleh suatu
                                        institusi untuk digunakan hanya di institusi tersebut.</li>
                                    <li class="number">Program beasiswa, sebagai bukti kecakapan bahasa Inggris akademik, seperti
                                        beasiswa LPDP, Fulbright Indonesia, dll.</li>
                                    <li class="number">Syarat melamar pekerjaan maupun kenaikan pangkat atau promosi untuk beberapa
                                        instansi negeri maupun swasta.</li>
                                </ol>
                                </p>
                            </div>
                        </div>
                        <div class="row justify-content-center">
                            <div class="page-title style1 col-12 mb-2 d-flex justify-content-between align-items-center">
                                <h2 class="text-grey-900 fw-700 font-lg mb-0 pb-3 d-block">Jadwal TOEFL iBT®</h2>
                                <router-link to="/login" class="btn register-button">Register</router-link>
                            </div>
                            <div class="col-12 mb-5">
                                <div class="table-responsive mw-100 px-1">
                                    <datatable :class="'table table-hover table-bordered'" :columns="columns"
                                        :filter="tableFilter" :data="data" :page="page" :perPage="10">
                                        <template name="default" slot-scope="{ row, index }">
                                            <tr v-if="row" class="text-center">
                                                <td>{{ index + 1 }}</td>
                                                <td>{{ formatDate(row.reg_date[0]) }} - {{ formatDate(row.reg_date[1]) }}</td>
                                                <td>{{ formatDate(row.test_date) }}</td>
                                            </tr>
                                            <tr v-else>
                                                <td colspan="3">Nothing to see here</td>
                                            </tr>
                                        </template>
                                    </datatable>
                                </div>
                                <datatable-pager v-model="page" type="abbreviated"></datatable-pager>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import moment from 'moment';
export default {
    data() {
        return {
            isLoad: true,
            data: [],
            page: 1,
            tableFilter: '',
            columns: [
                { label: 'No', field: '', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center', filterable: false, sortable: false },
                { label: 'Tanggal Pendaftaran', field: 'reg_date', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center', filterable: false, sortable: false },
                { label: 'Tanggal Tes', field: 'test_date', headerClass: 'border-0 bg-current text-white p-3', class: 'product-p text-center', filterable: false, sortable: false },
            ]
        }
    },
    created() {
        this.getITP()
    },
    methods: {
        async getITP() {
            await axios.get(`${process.env.VUE_APP_URL_API}/guest/testprogramIBT`).then(res => {
                this.data = res.data.data
                this.isLoad = false
            })
        },
        formatDate(date) {
            return moment(date).format('MMMM D, YYYY');
        },
    },
}
</script>

<style>
li.number {
    list-style: decimal !important;
    margin-left: 18px !important;
}
/* Default margin for desktop */
.content {
  margin-top: 48px !important;
}

/* Media query for tablet and mobile screens */
@media (max-width: 991.98px) {
  .content {
    margin-top: 96px !important;
  }

  .table-responsive {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }
}
</style>

<style scoped>
.register-button{
    padding: 10px 30px;
    border: 5px solid #213E7A;
    font-weight: 700;
    font-size: 18px;
    color: #213E7A;
}

.register-button:hover {
    background: #213E7A;
    color: #fff;
}
</style>